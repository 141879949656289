import { useLocation, useOutlet } from 'react-router-dom'
import { Suspense, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { NavTransition } from '@/components/NavTransition.tsx'
import { Footer } from '@/app/Footer.tsx'
import { Header } from '@/app/Header.tsx'
import { ScrollAssist } from '@/components/ScrollAssist.tsx'

const AnimatedOutlet = () => {
    const o = useOutlet()
    const [outlet] = useState(o)
    //    const isPresent = useIsPresent()

    return (
        <>
            <NavTransition>{outlet}</NavTransition>
        </>
    )
}

export const Layout = () => {
    const location = useLocation()
    return (
        <>
            <ScrollAssist />
            <div id={'top'} className='flex flex-col min-h-screen min-w-[375px]'>
                <Header />
                <div className='page relative'>
                    <div className='content main-index'>
                        <div className='main-content'>
                            <Suspense fallback={<span>Loading element in routes...</span>}>
                                <AnimatePresence mode='wait'>
                                    <AnimatedOutlet key={location.key} />
                                </AnimatePresence>
                            </Suspense>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
