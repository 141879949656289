import { Link } from 'react-router-dom'

export const Error404 = () => {
    return (
        <>
            <main>
                <div id='content' className='mt-[10%] py-10 flex-grow flex items-center'>
                    <div className='error_text max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8'>
                        <h1 className={'text-3xl'}>Error404</h1>
                        <br />{' '}
                        <Link to='/' className={'hover:text-[#963247] text-xl'}>
                            Back
                        </Link>
                    </div>
                </div>
            </main>
        </>
    )
}

export function Component() {
    return <Error404 />
}
