import { Link } from 'react-router-dom'

export const DataPrivacy = () => {
    return (
        <>
            <main id='content' className='py-10 flex-grow flex items-center text-center'>
                <div className='grid grid-cols-12 gap-4 mx-auto'>
                    <div className='col-span-10 col-start-2'>
                        <div className='coming_address'>
                            <span className='page_head'>Datenschutzerklärung</span>

                            <p>
                                Wir freuen uns über Ihren Besuch auf unserer Internetseite behringer-hausverwaltung.de
                                und Ihr Interesse an unserem Unternehmen. Der Schutz Ihrer personenbezogenen Daten, wie
                                z.B. Geburtsdatum, Name, Telefonnummer, Anschrift etc., ist uns ein wichtiges Anliegen.
                                Der Zweck dieser Datenschutzerklärung besteht darin, Sie über die Verarbeitung Ihrer
                                personenbezogenen Daten zu informieren, die wir bei einem Seitenbesuch von Ihnen
                                sammeln. Unsere Datenschutzpraxis steht im Einklang mit den gesetzlichen Regelungen der
                                Datenschutzgrundverordnung der EU (DSGVO) und dem Bundesdatenschutzgesetz (BDSG). Die
                                nachfolgende Datenschutzerklärung dient der Erfüllung der sich aus der DSGVO ergebenden
                                Informationspflichten. Diese finden sich z.B. in Art. 13 und Art. 14 ff. DSGVO.
                            </p>
                            <h1>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h1>
                            <p>
                                {' '}
                                Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen
                                Kontaktdaten können Sie dem{' '}
                                <Link to={'/imprint'} className={'text-[#666] hover:text-[#963247]'}>
                                    Impressum
                                </Link>{' '}
                                dieser Website entnehmen.
                            </p>

                            <h1>Kontaktdaten des Datenschutzbeauftragten</h1>
                            <p>
                                Wir haben einen externen Datenschutzbeauftragten gem. Art. 37 DSGVO bestellt. Unseren
                                Datenschutzbeauftragten erreichen Sie unter den nachfolgenden Kontaktdaten:
                                <br />
                                Frank Wiemer
                                <br />
                                Fraunhoferstraße 9<br />
                                ituso GmbH
                                <br />
                                85221 Dachau
                                <br />
                                Deutschland
                                <br />
                                E-Mail: Frank.Wiemer@ituso.de
                            </p>

                            <h1>Hosting der Webseite, Erstellung von Logfiles</h1>
                            <p></p>
                            <h2>Wir hosten die Inhalte unserer Website bei Hetzner Online GmbH</h2>
                            <p>
                                Anbieter ist die Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen (nachfolgend
                                Hetzner). Details entnehmen Sie der Datenschutzerklärung von
                                Hetzner:https://www.hetzner.com/de/legal/privacy-policy/. Die Verwendung von Hetzner
                                erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
                            </p>
                            <p>
                                Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten
                                Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich
                                vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten
                                unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO
                                verarbeitet.
                            </p>
                            <h1>
                                Welche personenbezogenen Daten werden erhoben und in welchem Umfang werden diese
                                verarbeitet?
                            </h1>
                            <p className='!mb-2'>
                                Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und
                                Informationen des jeweils abrufenden Gerätes (z.B. Computer, Mobiltelefon, Tablet,
                                etc.).
                            </p>
                            <ol className='list-decimal w-[50%] text-left mx-auto text-md text-[#9c9e9f]'>
                                <li>Informationen über den Browsertyp und die verwendete Version</li>
                                <li>Das Betriebssystem des Abrufgerätes</li>
                                <li>Hostname des zugreifenden Rechners</li>
                                <li>Die IP-Adresse des Abrufgerätes</li>
                                <li>Datum und Uhrzeit des Zugriffs</li>
                                <li>
                                    Websites und Ressourcen (Bilder, Dateien, weitere Seiteninhalte), die auf unserer
                                    Internetseite aufgerufen wurden
                                </li>
                                <li>
                                    Websites, von denen das System des Nutzers auf unsere Internetseite gelangte
                                    (Referrer-Tracking)
                                </li>
                                <li>Meldung, ob der Abruf erfolgreich war</li>
                                <li>Übertragene Datenmenge</li>
                            </ol>
                            <p>
                                Diese Daten werden in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser
                                Daten zusammen mit personenbezogenen Daten eines konkreten Nutzers findet nicht statt,
                                so dass eine Identifizierung einzelner Seitenbesucher nicht erfolgt.
                            </p>
                            <h2>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h2>
                            <p>
                                Art. 6 Abs. 1 lit. f DSGVO (berechtigtes Interesse). Unser berechtigtes Interesse
                                besteht darin, die Erreichung des nachfolgend geschilderten Zwecks zu gewährleisten.
                            </p>
                            <h2>Zweck der Datenverarbeitung</h2>
                            <p>
                                Die vorübergehende (automatisierte) Speicherung der Daten ist für den Ablauf eines
                                Websitebesuchs erforderlich, um eine Auslieferung der Website zu ermöglichen. Die
                                Speicherung und Verarbeitung der personenbezogenen Daten erfolgt zudem zur Erhaltung der
                                Kompatibilität unserer Internetseite für möglichst alle Besucher und zur
                                Missbrauchsbekämpfung und Störungsbeseitigung. Hierfür ist es notwendig, die technischen
                                Daten des abrufenden Rechners zu loggen, um so frühestmöglich auf Darstellungsfehler,
                                Angriffe auf unsere IT-Systeme und/oder Fehler der Funktionalität unserer Internetseite
                                reagieren zu können. Zudem dienen uns die Daten zur Optimierung der Website und zur
                                generellen Sicherstellung der Sicherheit unserer informationstechnischen Systeme.
                            </p>
                            <h2>Dauer der Speicherung</h2>
                            <p>
                                Die Löschung der vorgenannten technischen Daten erfolgt, sobald sie nicht mehr benötigt
                                werden, um die Kompatibilität der Internetseite für alle Besucher zu gewährleisten,
                                spätestens aber 3 Monate nach Abruf unserer Internetseite.
                            </p>
                            <h2>Widerspruchs- und Löschungsmöglichkeit</h2>
                            <p>
                                Sie können der Verarbeitung jederzeit gem. Art. 21 DSGVO widersprechen und eine Löschung
                                von Daten gem. Art. 17 DSGVO verlangen. Welche Rechte Ihnen zustehen und wie Sie diese
                                geltend machen, finden Sie im unteren Bereich dieser Datenschutzerklärung.
                            </p>
                            <h1>Funktionen der Webseite</h1>
                            <p>
                                Unsere Webseite bietet Ihnen verschiedene Funktionen, bei deren Nutzung von uns
                                personenbezogene Daten erhoben, verarbeitet und gespeichert werden. Nachfolgend erklären
                                wir, was mit diesen Daten geschieht:
                            </p>
                            <h2>Kontaktformular</h2>
                            <h3>
                                Welche personenbezogenen Daten werden erhoben und in welchem Umfang werden diese
                                verarbeitet?
                            </h3>
                            <p>
                                Die von Ihnen in unsere Kontaktformulare eingegebenen Daten, die Sie in die Eingabemaske
                                des Kontaktformulares eingetragen haben.
                            </p>
                            <h3>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h3>
                            <p>
                                Art. 6 Abs. 1 lit. a DSGVO (Einwilligung durch eindeutige bestätigende Handlung bzw.
                                Verhaltensweise).
                            </p>
                            <h3>Zweck der Datenverarbeitung</h3>
                            <p>
                                Die über unser Kontaktformular bzw. über unsere Kontaktformulare aufgenommenen Daten
                                werden wir nur für die Bearbeitung der konkreten Kontaktanfrage verwenden, die durch das
                                Kontaktformular eingeht. Bitte beachten Sie, dass wir zur Erfüllung ihrer Kontaktanfrage
                                Ihnen unter Umständen auch E-Mails an die angegebene Adresse senden können. Dies hat den
                                Zweck, dass Sie von uns eine Bestätigung erhalten können, dass Ihre Anfrage an uns
                                korrekt weitergeleitet wurde. Der Versand dieser Bestätigungs-E-Mail ist jedoch für uns
                                nicht verpflichtend und dient nur Ihrer Information.
                            </p>
                            <h3>Dauer der Speicherung</h3>
                            <p>
                                Nach Bearbeitung Ihrer Anfrage werden die erhobenen Daten unverzüglich gelöscht, soweit
                                keine gesetzlichen Aufbewahrungsfristen bestehen.
                            </p>
                            <h3>Widerrufs- und Löschungsmöglichkeit</h3>
                            <p>
                                Die Widerrufs- und Löschungsmöglichkeiten richten sich nach den nachfolgend in dieser
                                Datenschutzerklärung geschilderten generellen Regelungen zum datenschutzrechtlichen
                                Widerrufsrecht und Löschungsrecht.
                            </p>
                            <h3>Erforderlichkeit der Angabe personenbezogener Daten</h3>
                            <p>
                                Die Nutzung der Kontaktformulare erfolgt auf freiwilliger Basis und ist weder
                                vertraglich noch gesetzlich vorgeschrieben. Sie sind nicht verpflichtet mit uns über das
                                Kontaktformular Kontakt aufzunehmen, sondern können auch die weiteren, auf unserer Seite
                                angegebenen Kontaktmöglichkeiten, nutzen. Sofern Sie unser Kontaktformular nutzen
                                möchten, müssen Sie die als Pflichtangaben gekennzeichneten Felder ausfüllen. Sofern Sie
                                die notwendigen Angaben des Kontaktformulares nicht ausfüllen, können Sie entweder die
                                Anfrage nicht absenden oder wir können Ihre Anfrage leider nicht bearbeiten.
                            </p>
                            <h1>Statistische Auswertung, Webtracker</h1>
                            <p>
                                Auf unserer Webseite verwenden wir aktuell keine aktiven Inhalte (Webdienste) von
                                externen Anbietern.
                            </p>
                            <h1>Einbindung externer Webdienste</h1>
                            <p>
                                Auf unserer Webseite verwenden wir aktuell keine aktiven Inhalte (Webdienste) von
                                externen Anbietern.
                            </p>
                            <h1>Cookies</h1>
                            <p>
                                Wir verwenden innerhalb der Webseite temporäre und permanente Cookies. Als Cookies
                                werden kleine Dateien bezeichnet, die auf Ihrem Zugriffsgerät gespeichert werden.
                                Innerhalb der Cookies können unterschiedliche Angaben hinterlegt werden. Ein Cookie
                                dient primär dazu, Ihre Benutzerangaben oder auf das Gerät bezogene Informationen
                                während oder auch nach Ihrem Besuch des Onlineangebots zu speichern. Als temporäre
                                Cookies, bzw. Session-Cookies oder transiente Cookies, werden Cookies bezeichnet, die
                                gelöscht werden, nachdem Sie das Onlineangebot verlassen bzw. wenn Sie Ihren Browser
                                schliessen. In einem solchen Cookie kann z.B. der Login-Status gespeichert werden. Als
                                permanent oder persistent werden Cookies bezeichnet, die auch nach dem Schließen des
                                Browsers gespeichert bleiben. So kann z.B. der Login-Status wiederhergestellt werden,
                                wenn Sie die Webseite nach mehreren Tagen erneut aufsuchen. Ebenso können in einem
                                solchen Cookie Daten gespeichert werden, die für Reichweitenmessung oder Marketingzwecke
                                verwendet werden können. Cookies, die durch uns gesetzt werden, werden als First-Party
                                Cookies bezeichnet, als Third-Party-Cookie werden Cookies bezeichnet, die von
                                Drittanbietern angeboten werden.
                            </p>
                            <p>
                                Sie können die Verwendung von Cookies durch die Webseite jederzeit mittels einer
                                entsprechenden Einstellung in Ihrem Browser verhindern und damit der Setzung von Cookies
                                dauerhaft widersprechen. Ferner können bereits gesetzte Cookies jederzeit über Ihren
                                Browser oder andere Softwareprogramme gelöscht werden. Deaktivieren Sie die Setzung von
                                Cookies im genutzten Browser, sind eventuell nicht alle Funktionen der Webseite
                                vollumfänglich nutzbar.
                            </p>
                            <p>
                                Auf unserer Webseite verwenden wir aktuell ausschliesslich technisch notwendige Cookies.
                            </p>
                            <h1>
                                Ihre Datenschutzrechte: Auskunft über die personenbezogene Daten, Berichtigung,
                                Löschung, Einschränkung, Widerspruch
                            </h1>
                            <p>
                                Sie haben, bzw. jede betroffene Person hat das Recht auf Auskunft nach Art. 15 DSGVO,
                                das Recht auf Berichtigung und Verfollständigung nach Art. 16 DSGVO, das Recht auf
                                Löschung nach Art. 17 DSGVO, das Recht auf Einschränkung der Verarbeitung nach Art. 18
                                DSGVO, das Recht auf Widerspruch nach Art. 21 DSGVO sowie das Recht auf
                                Datenübertragbarkeit nach Art. 20 DSGVO. Beim Auskunftsrecht und beim Löschungsrecht
                                gelten die Einschränkungen nach §§ 34 und 35 BDSG. Darüber hinaus besteht gem. Art. 77
                                DSGVO ein Beschwerderecht bei der zuständigen Datenschutzaufsichtsbehörde.
                            </p>
                            <p>
                                Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für
                                die Zukunft zu widerrufen. Sie können der künftigen Verarbeitung der Sie betreffenden
                                Daten nach Maßgabe des Art. 21 DSGVO jederzeit widersprechen.
                            </p>
                            <p>
                                Möchten Sie dieses Auskunfts-, Berichtigungs-, Löschungs-, Einschränkungs- oder
                                Widerspruchsrecht in Anspruch nehmen, können Sie sich hierzu jederzeit unter den oben
                                angegebenen Kontaktdaten an uns wenden. Weiterhin stehen wir Ihnen für Fragen zur
                                Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten zur Verfügung.
                            </p>
                            <h1>
                                Dauer der Speicherung, routinemäßige Löschung und Sperrung von personenbezogenen Daten
                            </h1>
                            <p>
                                Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch
                                Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und
                                Informationsmaterialien wird hiermit widersprochen. Wir behalten uns ausdrücklich
                                rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen etwa
                                durch Spam-Emails vor.
                            </p>
                            <h1>Veröffentlichte Kontaktdaten</h1>
                            <p>
                                Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch
                                Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und
                                Informationsmaterialien wird hiermit widersprochen. Wir behalten uns ausdrücklich
                                rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen etwa
                                durch Spam-Emails vor.
                            </p>
                            <h1>Änderungen der Datenschutzerklärung</h1>
                            <p>
                                Wir behalten uns vor, diese Datenschutzerklärung jederzeit ohne gesonderte Ankündigung
                                zu ändern. Die geltende Datenschutzerklärung kann innerhalb der Webseite eingesehen
                                werden.
                            </p>
                            <p>Stand: August 2024</p>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export function Component() {
    return <DataPrivacy />
}
