import { Fragment, StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './index.scss'
import { routes } from '@/routes.tsx'

const enableStrictMode = false
// Create react app
let Wrapper = Fragment //fallback in case you dont want to wrap your components
if (enableStrictMode) {
    Wrapper = StrictMode
}

createRoot(document.getElementById('root')!).render(
    <Wrapper>
        <RouterProvider
            router={createBrowserRouter(routes)}
            future={{ v7_startTransition: true }}
            fallbackElement={<span>Loading element in main...</span>}
        />
    </Wrapper>
)
