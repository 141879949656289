import logo from '@/assets/logo_l.jpg'
import { Link } from 'react-router-dom'

export const Header = () => {
    return (
        <>
            <header className='bg-white'>
                <div className='grey-style-block'></div>
                <nav className='container mx-auto flex items-center justify-between mt-2'>
                    <Link to={'/'}>
                        <img src={logo} alt='Behringer' className='h-16' />
                    </Link>

                    <ul
                        className={`gap-2 static pl-16 top-2 md:top-16 md:left-0 bg-white lg:w-auto lg:space-x-6 p-4 lg:p-0 shadow-none transition-all duration-300 ease-in-out  inline-block md:flex`}>
                        <li>
                            <Link to={'/'} className='main-nav-link'>
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link to={'/data-privacy'} className='main-nav-link'>
                                Datenschutzerklärung
                            </Link>
                        </li>
                        <li>
                            <Link to={'/imprint'} className='main-nav-link'>
                                Impressum
                            </Link>
                        </li>

                    </ul>
                </nav>
            </header>
        </>
    )
}
