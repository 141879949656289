

export const Imprint = () => {
    return (
        <>
            <main id='content' className='py-10 flex-grow flex items-center text-center'>
                <div className='grid grid-cols-12 gap-4 mx-auto'>
                    <div className='col-span-10 col-start-2'>
                        <div className='coming_address'>
                            <div className='page_head'>Impressum</div>
                            <h1>Impressum / Anbieter</h1>
                            <p>

                                    Behringer Hausverwaltung GmbH<br/>
                                    Gewerbering 11<br/>
                                    86922 Eresing<br/>
                                Deutschland<br/>
                                </p>
                            <h1>Kontakt</h1>
                            <p>
                                E-Mail: info@behringer-hausverwaltung.de<br/><br/>
                                Telefon: 089 - 7442443 - 0<br/>
                                Telefax: 089 - 7442443 - 99
                                    <br />
                            </p>
                            <h1>Handelsregister</h1>
                            <p>Registergericht: Amtsgericht München<br />
                            Registernummer: HRB 139283</p>
                                <h1>Geschäftsführer</h1>
                            <p>Martin J. Müller</p>
                                    <h1>Inhaltlich Verantwortlich</h1>
                            <p>Martin J. Müller<br />
                            (Anschrift wie oben)</p>
                            <h1>Aufsichtsbehörde</h1>
                            <p>Berufsrechtliche Genehmigung für die Gesellschaft nach § 34c GewO durch die Landeshauptstadt München, Kreisverwaltungsreferat KVR-I/311 AG1 Ruppertstr. 19, 80466 München. Berufliche Genehmigung für Wohnungsverwaltung nach § 34c Absatz 1 Satz 1 GewO durch die IHK für München und Oberbayern, Max-Joseph-Straße 2, 80333 München.</p>
                            <h1>Umsatzsteuer</h1>
                            <p>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
                                DE219222407</p>

                            <h1>Urheberrecht und Bildnachweise</h1>
                            <p>Die Inhalte dieser Webseite sind – soweit nicht abweichend angegeben – urheberrechtlich geschützt.
                                Verwendete Fotografien sind mit Bildnachweisen gekennzeichnet oder im folgenden aufgeführt, soweit sie nicht selbst angefertigt wurden.
                                Die Verwendung von Fotografien auf Drittseiten ist nur im Rahmen der jeweiligen Lizenz der Urheber möglich.</p>
                            <h2>Bildnachweise</h2>
                            <p>Aktuell keine Bildmedien auf der Webseite.</p>
                            <h1>Haftung für Inhalte</h1>
                            <p>Wir bemühen uns die Inhalte unserer Seite aktuell zu halten. Trotz sorgfältiger Bearbeitung bleibt eine Haftung ausgeschlossen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.

                                Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen. Bei bekannt werden von Rechtsverletzungen, werden wir diese Inhalte umgehend entfernen. Eine diesbezügliche Haftung übernehmen wir erst ab dem Zeitpunkt der Kenntnis einer möglichen Rechtsverletzung.</p>
                            <h1>Haftung für Links</h1>
                            <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Für die Inhalte und die Richtigkeit der Informationen verlinkter Websites fremder Informationsanbieter wird keine Gewähr übernommen.

                                Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße ohne Beanstandung überprüft. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
                            <h1>EU-Streitschlichtung</h1>
                            <p>An einem Streitbeilegungsverfahren nach dem Verbraucherstreitbeilegungsgesetz (VSBG) nimmt unsere Gesellschaft/unsere Firmengruppe nicht teil.</p>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export function Component() {
    return <Imprint />
}
