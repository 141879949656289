import logo from '@/assets/logo_l.jpg'

export const Home = () => {
    return (
        <>
            <main>
                <div className='grid grid-cols-12 gap-4'>
                    <div className='col-span-6 col-start-4'>
                        <div className='mt-[40%]'>
                            <div className='row justify-content-md-center'>
                                <div className='col-md-6 align-center text-center justify-center'>
                                    <div className='max-w-96 m-auto'>
                                        <img src={logo} alt='Behringer Hausverwaltung GmbH' />
                                    </div>
                                    <div className='coming_address'>
                                        <p>
                                            Gewerbering 11 - 86922 Eresing
                                            <br />
                                            <span>Tel +49 (0) 89 7442 4430 - Fax +49 (0) 89 7442 44399</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export function Component() {
    return <Home />
}
